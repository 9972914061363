<template>
    <div
        ref="rootEl"
        class="p-3 rounded-lg flex relative"
        :class="[error ? 'bg-state-red' : 'bg-dark-light']"
        v-bind="$attrs"
    >
        <DotIcon v-if="!validated" pulse class="flex float-right absolute -right-2.5 -top-2.5" />

        <Loading v-if="!state.isIntersected" :when="true"></Loading>

        <div ref="cardContentRef" class="w-full h-full">
            <div
                v-if="!error && state.isIntersected"
                class="flex flex-col w-full h-full"
                :class="color ? 'border-l-2 -ml-1 pl-1.5' : ''"
                :style="color ? `border-color: ${color}` : ''"
            >
                <slot name="above-title" />
                <Blur
                    class="flex flex-col"
                    :blurred="blurred"
                    :title="blurredTitle"
                    :imagePath="imagePath"
                    :buttonText="$t('premium.button-text')"
                    :text="$t('premium.alogo-performance-premium')"
                >
                    <div
                        v-if="title || $slots.title"
                        class="flex md:space-y-0 space-y-2 md:flex-row flex-col justify-between mb-4 pb-3 border-b-2 border-dark-lighter"
                    >
                        <div class="flex" :class="{ 'cursor-pointer': collapsable }" @click.prevent="toggleIsVisible">
                            <div v-if="collapsable" class="w-6 h-6 justify-end">
                                <ArrowIcon :style="`transform: rotate(${degrees}deg)`" />
                            </div>
                            <h3>
                                <slot name="title">
                                    {{ title }}
                                </slot>
                            </h3>
                        </div>
                        <div v-if="$slots['title-right'] && (collapsable ? isVisible : true)"
                            ><slot name="title-right"
                        /></div>
                    </div>
                    <Loading :when="loading" v-if="collapsable ? isVisible : true" class="h-full">
                        <div class="w-full h-full">
                            <slot />
                        </div>
                    </Loading>
                </Blur>
            </div>
        </div>

        <template v-if="error && state.isIntersected">
            <div class="flex items-center justify-center text-center">
                {{ error }}
            </div>
        </template>
    </div>
</template>

<script setup>
import { ref, computed, reactive, watch, inject, onMounted, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import tailwind from '@/lib/tailwind'
import { shareContent } from '@/lib/helpers/export'

import Blur from '@/components/Blur.vue'
import Loading from '@/components/Loading.vue'
import DotIcon from '@/components/icons/DotIcon.vue'
import ArrowIcon from '@/components/icons/RightIcon.vue'

const props = defineProps({
    error: { type: String, default: undefined },
    shareable: { type: Boolean, default: true },
    shareTitle: { type: String, default: undefined },
    title: { type: String, default: undefined },
    loading: { type: Boolean, default: false },
    validated: { type: Number, default: 1 },
    visible: { type: Boolean, default: true },
    collapsable: { type: Boolean, default: false },
    blurred: { type: Boolean, default: false },
    blurredTitle: { type: String, default: '' },
    imagePath: { type: String, default: '' },
    color: { type: String, default: undefined },

    isIntersected: {
        type: Boolean,
        default: false,
    },
    idle: {
        type: Boolean,
        default: false,
    },
    /**
     * See IntersectionOberserver rootMargin [docs](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options)
     */
    rootMargin: {
        type: String,
        default: '0px 0px 0px 0px',
    },
    /**
     * See IntersectionOberserver treshold [docs](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options)
     */
    threshold: {
        type: [Number, Array],
        default: 0.4,
    },
})

const { t, locale } = useI18n()

const rootEl = ref(null)
const cardContentRef = ref(null)
const state = reactive({
    isIntersected: props.isIntersected,
    idle: props.idle,
    rootMargin: props.rootMargin,
    threshold: props.threshold,
    observer: null,
})
const emit = defineEmits(['update:visible'])

const isMobile = inject('isMobile')
const isLoading = inject('isLoading')

const isVisible = ref(props.visible)

const toggleIsVisible = () => setIsVisible(!isVisible.value)
const setIsVisible = (state) => {
    isVisible.value = state
    emit('update:visible', state)
}

watch(
    () => props.visible,
    (newIsVisible) => {
        setIsVisible(newIsVisible)
    }
)

const degrees = computed(() => (isVisible.value ? 90 : -90))

const observe = () => {
    const { rootMargin, threshold } = state
    const config = { root: null, rootMargin, threshold }
    state.observer = new IntersectionObserver(onIntersection, config)
    state.observer.observe(rootEl.value)
}

const onIntersection = (entries) => {
    state.isIntersected = entries.some(({ isIntersecting }) => isIntersecting)
    if (state.isIntersected) {
        unobserve()
    }
}

const unobserve = () => {
    if ('IntersectionObserver' in window && state.observer) {
        state.observer.unobserve(rootEl.value)
    }
}

let pressTimer // Variable pour stocker le minuteur de maintien appuyé

// Fonction pour démarrer le minuteur lorsqu'un élément est maintenu appuyé
const startPressTimer = () => {
    pressTimer = setTimeout(() => {
        console.log('cardContentRef?.value?.style', cardContentRef?.value?.style)
        isLoading.value = true

        // gray effect on card
        try {
            const previousColor = cardContentRef.value?.style?.backgroundColor || ''
            cardContentRef.value.style.backgroundColor = tailwind.theme.colors.dark.lightest
            setTimeout(async () => {
                cardContentRef.value.style.backgroundColor = previousColor
            }, 500)
        } catch (err) {}

        setTimeout(async () => {
            try {
                await shareContent(cardContentRef.value, props.shareTitle, isMobile)
            } catch (err) {
                console.log(err)
            }
            isLoading.value = false
        }, 500)
    }, 3000) // Durée de maintien appuyé en millisecondes (par exemple, 1 seconde)
}

// Fonction pour annuler le minuteur si l'utilisateur relâche l'élément prématurément
const cancelPressTimer = () => {
    clearTimeout(pressTimer)
}

onMounted(() => {
    if ('IntersectionObserver' in window) {
        if (!state.isIntersected && !state.idle) {
            observe()
        }
    } else {
        state.isIntersected = true
    }

    if (props.shareable) {
        // Événement de démarrage du maintien appuyé (pour les appareils tactiles)
        cardContentRef.value.addEventListener('touchstart', startPressTimer)

        // Événement d'annulation du maintien appuyé (pour les appareils tactiles)
        cardContentRef.value.addEventListener('touchend', cancelPressTimer)

        // Événement de démarrage du maintien appuyé (pour les appareils non tactiles)
        cardContentRef.value.addEventListener('mousedown', startPressTimer)

        // Événement d'annulation du maintien appuyé (pour les appareils non tactiles)
        cardContentRef.value.addEventListener('mouseup', cancelPressTimer)
    }
})
onBeforeUnmount(() => {
    if (!state.isIntersected && !state.idle) {
        unobserve()
    }
})
</script>
