<template>
    <label :title="!readOnly ? $t('user.change-your-avatar') : ''" @click="takePicture">
        <span class="inline-block relative">
            <img
                class="text-center inline-block rounded-full"
                :class="small ? 'h-20 w-20' : 'h-40 w-40'"
                :src="avatar"
                alt="avatar"
            />

            <span
                v-if="!readOnly"
                class="absolute bottom-0 right-0 block h-6 w-6 rounded-full text-white shadow-solid bg-dark-light"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                </svg>
            </span>
        </span>
    </label>

    <div
        v-show="showModal"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
        <div class="relative m-5 w-full max-w-2xl rounded-lg">
            <!--content-->
            <div
                class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-dark-base outline-none focus:outline-none"
            >
                <!--header-->
                <RawHeader
                    class="rounded-lg"
                    :back-text="$t('nav.cancel')"
                    :back-action="() => closeModal()"
                    :primary="false"
                    :withIcons="false"
                    :addPadding="false"
                >
                    {{ $t('user.crop-avatar') }}
                </RawHeader>
                <!--body-->
                <div class="h-full overflow-y-auto">
                    <div class="m-5">
                        <div ref="imageContainer"></div>
                        <div class="mt-5 rounded-md shadow-sm">
                            <Button :action="onCrop"> {{ $t('form.save-close') }} </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
</template>

<style>
.cropper-view-box,
.cropper-face {
    border-radius: 50%;
}
</style>

<style module>
.body {
    max-width: 768px;
    height: 100%;
}
/* Ensure the size of the image fit the container perfectly */
.image img {
    max-width: 100%;
}
</style>

<script>
import { ref } from 'vue'
import { Camera, CameraResultType } from '@capacitor/camera'
import { useI18n } from 'vue-i18n'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

import RawHeader from '@/components/RawHeader.vue'
import Button from '@/components/buttons/Primary.vue'

export default {
    components: { RawHeader, Button },
    props: {
        avatar: {
            type: String,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:avatar'],
    setup({ readOnly }, { emit }) {
        const showModal = ref(false)
        const cropper = ref(null)
        const url = ref('')
        const imageContainer = ref(null)
        const { t } = useI18n()

        const closeModal = () => {
            cropper.value.destroy()
            cropper.value = null
            showModal.value = false
        }
        const takePicture = async () => {
            if (!readOnly) {
                try {
                    const imageChoosed = await Camera.getPhoto({
                        quality: 100,
                        allowEditing: false,
                        resultType: CameraResultType.DataUrl,
                        promptLabelHeader: t('avatar.promptLabelHeader'), // 'Photo',
                        promptLabelCancel: t('avatar.promptLabelCancel'), // 'Cancel',
                        promptLabelPhoto: t('avatar.promptLabelPhoto'), // 'From Photos',
                        promptLabelPicture: t('avatar.promptLabelPicture'), // 'Take Picture',
                    })
                    url.value = imageChoosed.dataUrl
                    showModal.value = true
                    modalReady()
                } catch (e) {
                    console.log('avatar', e)
                }
            }
        }

        const modalReady = () => {
            let img = document.createElement('img')
            img.id = 'image'
            img.src = url.value

            // clean result before
            imageContainer.value.innerHTML = ''
            // append new image
            imageContainer.value.appendChild(img)

            cropper.value = new Cropper(img, {
                viewMode: 1,
                dragMode: 'move',
                aspectRatio: 1,
                autoCropArea: 1,
                minContainerWidth: 300,
                minContainerHeight: 300,
                center: false,
                zoomOnWheel: true,
                zoomOnTouch: true,
                cropBoxMovable: false,
                cropBoxResizable: true,
            })
        }
        const onCrop = () => {
            let canvas = cropper.value.getCroppedCanvas({
                // minWidth: 80,
                // minHeight: 80,
                maxWidth: 1024,
                maxHeight: 1024,
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high',
            })
            canvas.toBlob((blob) => {
                let file = new File([blob], 'avatar.png')
                emit('update:avatar', file)
                closeModal()
            })
        }
        return { imageContainer, closeModal, takePicture, showModal, onCrop }
    },
}
</script>
